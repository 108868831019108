.countdown-container{
    height: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number-slider {
    position: relative;
    text-align: center;
    align-items: center;
    display: inline-flex;
    height: 100%;
    width: 10%;
    overflow: hidden;
    font-size: 1.25vw;
    font-weight: bold;
}

.colon{
    transform: translateY(0%);
    margin: 0 0.025vw;
    font-size: 1.25vw;
}

.number-slider-current,
.number-slider-next {
    position: absolute;
    width: 100%;
}

.number-slider-next {
    transform: translateY(-100%);
}

.slide-out {
    transition: transform 0.5s ease;
    transform: translateY(100%);
}

.slide-in {
    transition: transform 0.5s ease;
    transform: translateY(0%);
}

.s1{
    transition: none;
    transform: translateY(0);
}

.s2{
    transition: none;
    transform: translateY(-100%);
}
